/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the body takes up the full height and width */
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif; /* Adjust to your preferred font-family */
}

/* Main container to ensure it takes up full width and is centered */
.container {
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px; /* Adjust padding as needed */
}

/* Ensure images, videos, and other media fit within their containers */
img, video {
  max-width: 100%;
  height: auto;
}

/* Ensure headings, paragraphs, and other text elements are styled consistently */
h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 1em;
}

/* Responsive grid layout */
.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1;
  padding: 10px; /* Adjust padding as needed */
}

@media (max-width: 768px) {
  .col {
    flex: 100%;
  }
}
