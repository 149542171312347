/* SoldEvents.css */
.sold-events-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .event-list {
    list-style-type: none;
    padding: 0;
  }
  
  .event-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
  }
  
  .event-header h3 {
    margin: 0;
    flex: 1 1 100%; /* Full width on small screens */
  }
  
  .event-header p {
    flex: 1 1 50%; /* 50% width on small screens */
    margin: 5px 0;
  }
  
  .expand-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #007bff;
    flex: 1 1 100%; /* Full width on small screens */
    text-align: right;
  }
  
  .event-details {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .ticket-list {
    list-style-type: none;
    padding: 0;
  }
  
  .ticket-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .total-price {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .error {
    color: red;
  }
  
  @media (max-width: 768px) {
    /* Adjustments for tablet and mobile views */
    .sold-events-container {
      padding: 15px;
    }
  
    .event-header {
      flex-direction: column; /* Stack elements vertically on smaller screens */
    }
  
    .event-header p, .event-header h3 {
      text-align: left; /* Align text to the left */
    }
  
    .expand-btn {
      text-align: center; /* Center the expand button */
    }
  
    .event-details {
      padding-left: 10px;
    }
  }
  