/* Styles for the Settings component */
.settings-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    box-sizing: border-box;
}

.settings-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
}

.delete-account-button {
    background-color: white;
    color: red;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid red;
    margin-top: 20px;
    cursor: pointer;
}

.delete-confirmation {
    margin-top: 20px;
}

.delete-confirmation-input {
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid red;
    background-color: #ffe6e6;
}

.confirm-delete-button {
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-delete-button {
    background-color: grey;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .settings-card {
        padding: 15px;
        margin: 20px 10px; /* Add margin to avoid touching the edges */
    }
}

@media (max-width: 480px) {
    .settings-card {
        padding: 10px;
        margin: 20px 10px; /* Add margin to avoid touching the edges */
    }
}
