/* src/styles/Home.css */
.container {
  text-align: center;
  padding: 10px;
  background-color: #f0f2f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
}

.heading {
  margin-top: 15px;
  font-size: 2rem;
  color: #333;
  margin-bottom: 5px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

