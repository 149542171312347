.payment-container-unique {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.payment-card-unique {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.payment-input-unique {
  width: 100px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.payment-button-unique {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  margin: 5px;
}

.payment-link-unique {
  color: #63d4e9; /* Updated blue color for item title and event names */
  cursor: pointer;
  font-weight: bold; /* Make the text stand out */
}

.tip-container-unique {
  margin-top: 20px;
  text-align: center;
}

.tip-input-unique {
  width: 100px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.tip-button-unique {
  padding: 5px 10px;
  border-radius: 3px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  margin: 5px;
}

/* New card style for the Cart section */
.cart-card {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9; /* Light background color */
  transition: 0.3s;
}
