.card {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .cardTitle {
    font-size: 1.5em;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
  }
  
  .inputGroup {
    margin-bottom: 15px;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;
  }
  
  .message {
    margin-top: 15px;
    font-size: 1em;
    text-align: center;
    color: #007BFF;
  }
  
  .dateSection {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .seatingSection {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  