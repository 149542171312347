.business-page-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  justify-items: center; /* Center all items horizontally */
}

.image {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the image */
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%; /* Ensure the card takes up full width */
  max-width: 600px; /* Optional: Control the max-width */
}

.card h1, .card h2 {
  margin-top: 0;
}

.card p {
  margin: 10px 0;
}
