/* Styles for the Nav component */
nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Align items vertically with the logo */
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Add margin to separate links */
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.nav-welcome {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: white;
  margin-right: 10px;
}

/* Styles for the collapsible menu */
@media (max-width: 768px) {
  nav {
      display: none; /* Hide nav links by default */
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 10px; /* Add padding to avoid being too close to the top */
  }

  nav.open {
      display: flex; /* Show nav links when menu is open */
  }

  .nav-link {
      width: 100%; /* Make nav links take full width */
      text-align: center;
      margin: 5px 0; /* Add margin to separate the links vertically */
  }
}

@media (min-width: 769px) {
  .nav-link {
      margin-top: 0; /* Ensure nav links are parallel with the logo */
  }
}
