.event-selection-container {
  padding: 20px;
}

.event-toggle {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.event-card {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.event-title {
  text-align: center;
  font-size: 1.25em;
  margin-bottom: 10px;
}

.event-date {
  margin-bottom: 15px;
}

.seatings-label {
  text-align: center;
  font-size: 1.15em;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-seating {
  margin-left: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd; /* Separator line at the top with grey color */
}

.seating-number {
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.event-seating p {
  margin: 5px 0;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
