.collapse-button {
    background-color: transparent; /* No background color */
    color: black; /* Black text color */
    border: none;
    padding: 8px 12px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    border-bottom: 2px solid gray; /* Gray underline */
  }
  
  .collapse-button:hover {
    background-color: #f0f0f0; /* Optional: a light hover effect for better UX */
  }
  
  .ticket-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .ticket-id {
    margin-bottom: 8px;
  }
  
  .event-list-item {
    list-style-type: disc; /* Keep the default marker */
    padding-left: 10px; /* Adjust padding to bring marker closer */
    margin-left: 10px; /* Remove any margin */
  }