/* GuestStyles.css */

.guest-signin-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding-top: 20px; /* Adjust this value as needed */
    padding-left: 1rem; /* Add padding for left and right */
    padding-right: 1rem; /* Add padding for left and right */
  }
  
  .guest-signin-card {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin-bottom: 20rem; /* Adjust bottom margin */
  }
  
  .guest-signin-card h1 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
  }
  
  .guest-signin-card input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .guest-signin-card .button {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .guest-signin-card .button-secondary {
    background: none;
    border: 1px solid #ccc;
    color: #333;
  }
  
  .guest-signin-card .button-secondary:hover {
    background: #f0f0f0;
  }
  
  .guest-signin-link {
    margin-top: 1rem;
    font-size: 0.875rem;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .guest-signin-card {
      padding: 1.5rem;
      margin-bottom: 40rem; /* Adjust bottom margin */
    }
  
    .guest-signin-card input {
      padding: 0.5rem;
    }
  
    .guest-signin-card .button {
      padding: 0.5rem;
    }
  }
  