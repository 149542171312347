.about-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.about-page h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Arsenal SC', sans-serif;
}

.about-content {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  font-size: 1.1rem;
}

.about-card {
  background-color: #f0f8ff; /* Light background color */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increased shadow */
  color: black; /* Text color set to black */
}

.highlight {
  color: #63d4e9;
  font-weight: bold;
}

@media (max-width: 768px) {
  .about-page {
    padding: 15px;
  }

  .about-page h1 {
    font-size: 2rem;
  }

  .about-content {
    font-size: 1rem;
  }
}
