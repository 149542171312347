/* Styles for the Header component */
.header {
  padding: 20px;
  position: relative;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--logo {
  font-size: 2rem;
  color: white; /* Set font color to white */
  margin: 0;
  padding: 0;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.menu-button {
  display: none; /* Hidden by default */
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.menu-list--mobile {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.menu-list--mobile.open {
  display: flex;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .menu-button {
    display: block; /* Show menu button on small screens */
  }
  .menu-list--desktop {
    display: none;
  }
}
