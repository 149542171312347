/* Container for the card */
.card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

/* Input styles */
.card input[type="text"],
.card input[type="email"],
.card input[type="password"],
.card input[type="number"] {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 18px; /* Enlarged font size */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.card input[type="text"]:focus,
.card input[type="email"]:focus,
.card input[type="password"]:focus,
.card input[type="number"]:focus {
  border-color: #333;
  outline: none;
}

/* Styles for the labels */
.card label {
  display: block;
  margin-bottom: 5px;
  font-size: 18px; /* Enlarged font size */
  font-weight: bold;
}

.text-center {
  text-align: center;
}

/* Button styles */
.button {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-remove {
  background-color: #dc3545;
}

/* Margin styles */
.margin-medium {
  margin-bottom: 20px;
}

/* Paragraph styles */
.paragraph {
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 30px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .card {
      padding: 15px;
      margin: 20px 10px; /* Add margin to avoid touching the edges */
  }

  .button {
      width: 100%;
      margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .card {
      padding: 10px;
      margin: 20px 10px; /* Add margin to avoid touching the edges */
  }

  .button {
      width: 100%;
      margin-bottom: 10px;
  }
}
