.stripe-pay-container {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stripe-pay-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.stripe-pay-form input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.stripe-pay-form .CardElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.stripe-pay-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.stripe-pay-form button[disabled] {
  background-color: #cccccc;
}

.stripe-pay-form button:hover:not([disabled]) {
  background-color: #0056b3;
}

.stripe-pay-form .error-message {
  color: red;
  margin-top: 15px;
}

.stripe-pay-form .success-message {
  color: green;
  margin-top: 15px;
}
