/* Styles for the Signin component */
.signin.card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 40px auto; /* Increase margin to move the card down */
  box-sizing: border-box;
}

.pad-bottom {
  margin-top: 20px;
}

.button {
  background-color: var(--accentColor);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-secondary {
  background-color: transparent;
  color: var(--accentColor);
  padding: 10px 15px;
  border: 1px solid var(--accentColor);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-secondary:hover {
  background-color: var(--accentColor);
  color: white;
}

.signin-link {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: var(--accentColor);
}

.signin-link a {
  color: var(--accentColor);
  text-decoration: none;
}

.signin-link a:hover {
  text-decoration: underline;
}
