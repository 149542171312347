/* EventSummary.css */

/* Container for all events */
.event-summary-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Card Container */
.event-card {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
  text-align: left;
}

/* Title Styling */
.event-card h1 {
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 16px;
}

/* Paragraph Styling */
.event-card p {
  font-size: 16px;
  color: #555555;
  margin-bottom: 12px;
}

/* Bold Date Styling */
.date-section strong {
  font-weight: 700;
  margin-bottom: 8px;
}

/* Indented Seatings and Price */
.seating-section,
.price {
  margin-left: 20px;
  font-size: 16px;
  color: #555555;
  margin-bottom: 8px;
}

/* Message Styling */
.message {
  font-size: 14px;
  color: #e74c3c;
  margin-top: 16px;
}

/* Button Styling */
.button {
  background-color: #e74c3c;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #c0392b;
}

/* Loading State Styling */
.loading {
  text-align: center;
  font-size: 18px;
  color: #888888;
  margin-top: 20px;
}
